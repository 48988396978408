import React from 'react';
import { Grid } from '@material-ui/core';
import { CardEntity } from 'components/organisms';

const EntitiesGrid = ({ dataPlot, ...rest }: EntitiesGrid): JSX.Element => {
  return (
    <>
      <Grid container spacing={0}>
        {dataPlot.map(node => {
          const item = {
            id: node._id,
            images: [{ src: node.banner_img, alt: node.name }],
            name: node.name,
            description: node.description,
            tagline: node.tagline,
            industries: node.industries.data,
            geographies: node.geographies.data,
            supported_solutions: node.supported_solutions
              ? node.supported_solutions.data
              : null,
            logo_img: { src: node.logo_img, alt: node.name },
          };
          return (
            <Grid key={node._id} item xs={12} sm={6} md={4}>
              <CardEntity {...item} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default EntitiesGrid;
