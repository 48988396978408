import React, { useState } from 'react';
import { makeStyles, Divider, Grid, Box, Button } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { EntitiesShowcase, EntitiesFilters } from './components';
import { useStaticQuery, graphql } from 'gatsby';
import { DescriptionCta } from 'components/molecules';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  filterGrid: {
    marginBottom: '30px',
  },
}));

const AllEntities = ({ themeMode, location }): JSX.Element => {
  const classes = useStyles();

  let stateFilters;
  try {
    stateFilters = location.state;
  } catch {
    // Do nothing
  }

  const empty_filter: EntityFilter = {
    cursor: null,
    industries: [],
    geographies: [],
    number_employees: [],
    status: [],
  };

  const [filters, setFilters] = useState({ ...empty_filter, ...stateFilters });

  const data = useStaticQuery(
    graphql`
      query filterEntityInputs {
        fauna {
          allStatusOptions(_size: 500) {
            data {
              name
              _id
            }
          }
          allIndustries(_size: 500) {
            data {
              _id
              name
            }
          }
          allGeographies(_size: 500) {
            data {
              _id
              name
            }
          }
          allNumberEmployeesOptions(_size: 500) {
            data {
              _id
              name
            }
          }
        }
      }
    `,
  );

  return (
    <div className={classes.root}>
      <Section>
        <Grid container className={classes.filterGrid}>
          <Grid item xs={6} sm={3}>
            <EntitiesFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allIndustries.data}
              name="Industries"
              filterKey="industries"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <EntitiesFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allGeographies.data}
              name="Geographies"
              filterKey="geographies"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <EntitiesFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allNumberEmployeesOptions.data}
              name="Number of employees"
              filterKey="number_employees"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <EntitiesFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allStatusOptions.data}
              name="Status"
              filterKey="status"
            />
          </Grid>
        </Grid>
        <EntitiesShowcase filters={filters} />
      </Section>
      <Section>
        <Box
          marginBottom={2}
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          padding={2}
          border="1px solid #ccc"
          borderRadius="4px"
          bgcolor="alternate.main"
        >
          <DescriptionCta
            title={<b>Not seeing a company you know?</b>}
            subtitle="Add it to the database to share it with the Ftprint community."
            primaryCta={
              <Button
                variant="contained"
                color="primary"
                size="large"
                href="/contribute-solutions/"
              >
                Add a solution
              </Button>
            }
            align="left"
          />
        </Box>
      </Section>
    </div>
  );
};

export default AllEntities;
