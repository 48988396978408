const filterKeyInString = [
	'value_chain_impact',
	'scope_of_operations',
	'status',
	'number_employees',
];
const filterKeyForEntity = [
	'scope_of_operations',
	'status',
	'number_employees',
];

exports.filterKeyInString = filterKeyInString;
exports.filterKeyForEntity = filterKeyForEntity;
