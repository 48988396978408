import React from 'react';
import AllEntities from 'views/AllEntities';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const AllEntitiesPage = ({ location }): JSX.Element => {
	return (
		<WithLayout
			component={AllEntities}
			layout={Main}
			location={location}
			key={Date.now()}
		/>
	);
};

export default AllEntitiesPage;
